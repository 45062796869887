import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";
import Swal from "sweetalert2";

import { getPatient } from "~/src/api-client/patients";
import { getSpecialists, sendDerivationEmail } from "~/src/api-client/specialists";
import { updateTelehealthSpecialist } from "~/src/api-client/telehealth";
import ButtonLink from "~/src/components/common/inputs/buttons/ButtonLink";
import DashboardLayout from "~/src/components/DashboardLayout";
import CollapsibleSpecialities from "~/src/components/specifics/CollapsibleSpecialities";
import { useContextUpdate, useContextValue } from "~/src/context";
import ArrowLeftIcon from "~/src/icons/ArrowLeftIcon";
import { User } from "~/src/types/models";
import { AllSpecialities } from "~/src/types/specialities";

type PatientDerivationProps = {
  id: string;
};

const PatientDerivation = ({ id }: PatientDerivationProps) => {
  const patientId: string = id;
  const [patient, setPatient] = useState<User | undefined>(undefined);
  const [specialists, setSpecialists] = useState<AllSpecialities | undefined>(undefined);
  const { session, rehydrated, medic, preLoading } = useContextValue();
  const { setPreLoadingStatus } = useContextUpdate();

  const fetchData = async (queryPatientId: string) => {
    if (session) {
      setPreLoadingStatus(true);
      const specialities = await getSpecialists();
      const patientData = await getPatient(session.accessToken, queryPatientId);
      if (patientData && specialities) {
        setSpecialists(specialities);
        setPatient(patientData);
        setPreLoadingStatus(false);
      }
    }
  };

  useEffect(() => {
    if (rehydrated) {
      if (!session) {
        navigate("/");
      } else if (medic && !medic.active) {
        navigate("/app");
      } else {
        if (patientId) {
          fetchData(patientId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated, medic, session]);

  async function derivationEmail(specialist: string) {
    if (!patient) return;
    const derivationData = {
      patient_name: patient.first_name,
      patient_email: patient.email,
      speciality_name: specialist,
    };
    if (session) {
      Swal.showLoading();
      const res = await sendDerivationEmail(session?.accessToken, derivationData);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Derivación enviada",
        });
      }
      return res;
    }
  }

  async function updateTelehealth(specialist: string) {
    const res = await updateTelehealthSpecialist(patientId, specialist);
    return res;
  }

  function Header() {
    return (
      <div className={clsx("md:py-7", "w-full", "flex items-center justify-between", "text-examedi-gray-2")}>
        <div>
          <div className="text-lg md:text-[1.75rem] font-bold">{patient?.fullname}</div>
          <div className="text-xs md:text-xl  mt-[5px]">Deriva con especialista</div>
        </div>
      </div>
    );
  }

  function PreHeader() {
    return <ButtonLink text="Volver" to="/app/telehealth/" icon={<ArrowLeftIcon />} className="link w-fit text-xl" />;
  }

  function Derivation() {
    return (
      <>
        {!preLoading && !specialists && <p>Derivaciones no disponibles</p>}
        {specialists && (
          <div className={clsx("flex justify-between", "bg-white", "items-center")}>
            <CollapsibleSpecialities
              items={specialists}
              derivationEmailCallback={derivationEmail}
              updateCallback={updateTelehealth}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <DashboardLayout containerClassName="p-5 md:px-10" header={<Header />} preHeader={<PreHeader />}>
      <Derivation />
    </DashboardLayout>
  );
};

export default PatientDerivation;
