import React, { ReactElement, useEffect, useRef, useState, useCallback, useMemo } from "react";

import clsx from "clsx";

import GreaterThanArrrowIcon from "~/src/icons/GreaterThanArrowIcon";

type CollapsibleHandlerProps = {
  allowMultipleOpened?: boolean;
  children: React.ReactNode[];
  className?: string;
};

export function CollapsibleHandler({ allowMultipleOpened, children, className }: CollapsibleHandlerProps) {
  const [currentOpenedKey, setCurrentOpenedKey] = useState<React.Key | null>(null);

  const handleKeyChange = useCallback(
    (key: React.Key | null) => {
      const timeout = currentOpenedKey !== null ? 400 : 0;
      if (currentOpenedKey !== null) setCurrentOpenedKey(null);
      setTimeout(() => {
        setCurrentOpenedKey(key);
      }, timeout);
    },
    [currentOpenedKey],
  );

  const handledChildren = useMemo(() => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child) && !allowMultipleOpened) {
        let externalCollapsedState = true;
        if (currentOpenedKey == child.key) externalCollapsedState = false;
        return React.cloneElement(child, {
          ...child.props,
          stateKey: child.key,
          externalCollapsedState,
          changeKeyCallback: handleKeyChange,
        });
      }
      return child;
    });
  }, [currentOpenedKey, children, allowMultipleOpened, handleKeyChange]);

  return <div className={className}>{handledChildren}</div>;
}

type CollapsibleProps = {
  externalCollapsedState?: boolean;
  disabled?: boolean;
  preventChangeState?: boolean;
  head: string | JSX.Element;
  icon?: JSX.Element;
  iconRotationClassName?: string;
  collapsedIcon?: JSX.Element;
  notCollapsedIcon?: JSX.Element;
  body?: JSX.Element;
  className?: string;
  headClassName?: string;
  headNotCollapsedClassName?: string;
  headNotCollapsedTextClassName?: string;
  headCollapsedClassName?: string;
  headCollapsedTextClassName?: string;
  headCollapsedIconColor?: string;
  headNotCollapsedIconColor?: string;
  bodyClassName?: string;
  onCollapsed?: (arg: boolean) => void;
  changeKeyCallback?: (key: React.Key | null) => void;
  stateKey?: React.Key;
  children?: ReactElement | undefined;
  focusedWhenOpen?: boolean;
};

function Collapsible({
  externalCollapsedState,
  disabled,
  preventChangeState,
  head,
  icon,
  iconRotationClassName,
  collapsedIcon,
  notCollapsedIcon,
  body,
  className,
  headClassName,
  headNotCollapsedClassName,
  headNotCollapsedTextClassName,
  headCollapsedClassName,
  headCollapsedTextClassName,
  headCollapsedIconColor,
  headNotCollapsedIconColor,
  bodyClassName,
  onCollapsed,
  changeKeyCallback,
  stateKey,
  children,
  focusedWhenOpen,
}: CollapsibleProps) {
  const [collpasedEnd, setCollapsedEnd] = useState<boolean | undefined>();
  const [collapsedState, setCollapsedState] = useState<boolean>(true);
  const [preventTransition, setPreventTransition] = useState<boolean>(true);
  const [bodyHeight, setBodyHeight] = useState<string>("auto");
  const elementRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  function onClickHeadHandler() {
    if (!preventChangeState) {
      if (stateKey !== undefined && changeKeyCallback) {
        if (collapsedState) changeKeyCallback(stateKey);
        else changeKeyCallback(null);
        return;
      }
      setCollapsedState(!collapsedState);
    }
  }

  // To prevent the close transition when load
  useEffect(() => {
    setTimeout(() => setPreventTransition(false), 500);
  }, []);

  useEffect(() => {
    let timeouToken1: NodeJS.Timeout | undefined;
    let timeouToken2: NodeJS.Timeout | undefined;
    if (collapsedState) {
      setBodyHeight(bodyRef.current!.clientHeight + "px");
      timeouToken1 = setTimeout(() => {
        setBodyHeight("0px");
        onCollapsed?.(false);
        timeouToken2 = setTimeout(() => {
          setCollapsedEnd(true);
        }, 300);
      }, 100);
    } else {
      setCollapsedEnd(false);
      setBodyHeight(bodyRef.current!.scrollHeight + "px");
      onCollapsed?.(true);
      timeouToken1 = setTimeout(() => {
        setBodyHeight("auto");
      }, 300);
      if (focusedWhenOpen && elementRef.current) {
        const element = elementRef.current;
        const headerOffset = 150;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
    return () => {
      if (timeouToken1) clearTimeout(timeouToken1);
      if (timeouToken2) clearTimeout(timeouToken2);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedState]);

  useEffect(() => {
    if (externalCollapsedState !== undefined) {
      setCollapsedState(externalCollapsedState);
    }
  }, [externalCollapsedState]);

  return (
    <div ref={elementRef} className={className}>
      <button
        onClick={onClickHeadHandler}
        disabled={disabled}
        className={clsx(
          "w-full",
          "px-3 py-4 md:px-7",
          "flex items-center justify-between",
          "gap-x-2",
          "transition-all",
          "rounded-xl lg:rounded-t-lg lg:rounded-b-none",
          headClassName,
          collapsedState && ["disabled:!bg-examedi-gray-5", headCollapsedClassName],
          !collapsedState && ["disabled:!bg-examedi-gray-3", headNotCollapsedClassName],
          collpasedEnd && "rounded-b-xl lg:rounded-b-lg",
        )}
      >
        {typeof head === "string" && (
          <div
            className={clsx(
              collapsedState && (headCollapsedTextClassName || "text-examedi-black-light"),
              !collapsedState && (headNotCollapsedTextClassName || "text-examedi-black-light"),
              "w-fit",
              "overflow-ellipsis",
            )}
          >
            {head}
          </div>
        )}
        {typeof head !== "string" && React.cloneElement(head, { isCollapsed: collapsedState })}
        {!!collapsedIcon && collapsedState && collapsedIcon}
        {!!notCollapsedIcon && !collapsedState && notCollapsedIcon}
        {!collapsedIcon &&
          !notCollapsedIcon &&
          icon &&
          React.cloneElement(icon, {
            isCollapsed: collapsedState,
            className: clsx(
              (icon.props as { className: string }).className,
              "transition-all",
              !collapsedState && ["rotate-90", "!stroke-[" + headNotCollapsedIconColor + "]", iconRotationClassName],
              collapsedState && ["!stroke-[" + headCollapsedIconColor + "]"],
            ),
          })}
        {!collapsedIcon && !notCollapsedIcon && !icon && (
          <GreaterThanArrrowIcon
            strokeWidth={2}
            className={clsx(
              "inline-block",
              "h-4 w-4",
              "ml-5",
              "whitespace-nowrap",
              "transition-all",
              !collapsedState && ["rotate-90", "!stroke-[" + headNotCollapsedIconColor + "]"],
              collapsedState && ["!stroke-[" + headCollapsedIconColor + "]"],
            )}
          />
        )}
      </button>
      <div
        ref={bodyRef}
        className={clsx(
          "w-full",
          !preventTransition && ["border-examedi-gray-light", "ease transition-all duration-300"],
          preventTransition && "hidden",
          "rounded-b-lg",
          "overflow-hidden",
          // collpasedEnd && "border-none",
          bodyClassName,
        )}
        style={{ height: bodyHeight }}
      >
        {body || children}
      </div>
    </div>
  );
}

export default React.memo(Collapsible);
