import client from "~/src/api-client";

export async function updateTelehealthSpecialist(patient_id: string, specialist: string) {
  try {
    const res = await client.post("v2/telehealth/derived/", {
      patient_id,
      specialist,
    });
    return res.data;
  } catch (err) {
    return undefined;
  }
}
