import React from "react";

import clsx from "clsx";

function ArrowLeftIcon(props: React.ComponentPropsWithoutRef<"svg"> & { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx("fill-g1", props.className)}
    >
      <path d="M21 11.714C21 12.0676 20.7026 12.3568 20.333 12.3568H5.17385L10.827 18.3556C11.0744 18.618 11.0542 19.025 10.7821 19.2632C10.6539 19.3721 10.4931 19.4283 10.3324 19.4283C10.1514 19.4283 9.97046 19.358 9.83903 19.2174L3.17333 12.1459C2.94222 11.901 2.94222 11.5267 3.17333 11.2821L9.83903 4.21054C10.0877 3.94689 10.5096 3.92929 10.7812 4.16659C11.0534 4.40517 11.0735 4.81186 10.8262 5.07423L5.17385 11.0711H20.3334C20.7026 11.0711 21 11.3604 21 11.714Z" />
    </svg>
  );
}

export default ArrowLeftIcon;
