import React from "react";

function GreaterThanArrrowIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.91992 2L9.84006 9.92014L1.91992 17.8403" stroke="#7D7D7D" strokeWidth="3.57" strokeLinecap="round" />
    </svg>
  );
}

export default GreaterThanArrrowIcon;
