import client from "~/src/api-client";

type derivateEmailPayload = {
  patient_email: string;
  patient_name: string;
  speciality_name: string;
};

export async function getSpecialists() {
  try {
    const res = await client.get("v2/rescare/specialties/");
    return res.data;
  } catch (err) {
    return undefined;
  }
}

export async function sendDerivationEmail(
  token: string,
  derivationPayload: derivateEmailPayload,
): Promise<derivateEmailPayload | undefined> {
  try {
    const res = await client.post<derivateEmailPayload>("/medic-referrals/email_derivation/", derivationPayload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (err) {
    return undefined;
  }
}
