import React from "react";

import clsx from "clsx";

function ChevronRightIcon(props: React.ComponentPropsWithoutRef<"svg"> & { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx("fill-g1", props.className)}
    >
      <path d="M7.20765 20.8308C7.06952 20.7046 7.0005 20.532 7.0005 20.3587C7.0005 20.2024 7.05699 20.0461 7.17125 19.9224L14.4474 12.0034L7.17109 4.07913C6.93003 3.81798 6.94509 3.41058 7.2075 3.17072C7.46866 2.92965 7.87405 2.94596 8.11511 3.2065L15.8292 11.5635C16.0578 11.8108 16.0578 12.1886 15.8292 12.4361L8.11511 20.7931C7.873 21.0518 7.46881 21.0719 7.20765 20.8308Z" />
    </svg>
  );
}

export default ChevronRightIcon;
