import React from "react";

import clsx from "clsx";

import Collapsible, { CollapsibleHandler } from "~/src/components/common/Collapsible";
import ChevronRightIcon from "~/src/icons/ChevronRightIcon";
import { AllSpecialities } from "~/src/types/specialities";

type CollapsibleSpecialitiesProps = {
  items: AllSpecialities;
  allowMultipleOpened?: boolean;
  collapsedIcon?: React.ReactElement;
  notCollapsedIcon?: React.ReactElement;
  head?: React.ReactElement;
  headCollapsedClassName?: string;
  headNotCollapsedClassName?: string;
  titleClassName?: string;
  bodyClassName?: string;
  derivationEmailCallback: (specialist: string) => void;
  updateCallback: (specialist: string) => void;
};

function CollapsibleSpecialities({
  items,
  allowMultipleOpened,
  collapsedIcon,
  notCollapsedIcon,
  head,
  headCollapsedClassName,
  headNotCollapsedClassName,
  titleClassName,
  bodyClassName,
  derivationEmailCallback,
  updateCallback,
}: CollapsibleSpecialitiesProps) {
  return (
    <CollapsibleHandler
      allowMultipleOpened={allowMultipleOpened}
      className={clsx(
        "w-full",
        "rounded-2xl",
        "px-6",
        "border border-examedi-gray-line",
        "divide-y divide-examedi-gray-line",
      )}
    >
      {items.specialties.map((item, index) => {
        return (
          <Collapsible
            key={index}
            head={
              <>
                {head && React.cloneElement(head, { children: item.name })}
                {!head && (
                  <div className="flex flex-col gap-y-1">
                    <p
                      className={clsx(
                        "text-lg lg:text-base 2lg:text-lg xl:text-xl 2xl:text-2xl",
                        "text-left",
                        "text-examedi-black-dark",
                        "font-medium",
                        titleClassName,
                      )}
                    >
                      {item.name}
                    </p>
                    <div className="flex gap-x-3 flex-wrap gap-y-3">
                      {item.medical_treatments.map((treatment, idx) => (
                        <div
                          key={idx}
                          className={clsx(
                            "bg-examedi-blue-strong-05 rounded-full",
                            "px-4 py-1",
                            "text-examedi-blue-strong",
                          )}
                        >
                          {treatment}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            }
            className="py-4"
            collapsedIcon={collapsedIcon || <ChevronRightIcon />}
            notCollapsedIcon={notCollapsedIcon || <ChevronRightIcon className="rotate-90" />}
            bodyClassName={bodyClassName}
            headCollapsedClassName={clsx("!p-0", "group", headCollapsedClassName)}
            headNotCollapsedClassName={clsx("!p-0", headNotCollapsedClassName)}
            body={
              <div className="flex flex-col gap-y-4 px-2 py-4 w-full">
                <p className="font-medium text-examedi-gray-strong justify">{item.description}</p>
                <button
                  onClick={() => {
                    updateCallback(item.name);
                    derivationEmailCallback(item.name);
                  }}
                  className={clsx(
                    "w-1/5",
                    "ml-2",
                    "px-4",
                    "py-2",
                    "rounded-full",
                    "font-medium",
                    "text-white",
                    "border border-examedi-blue-strong hover:border-examedi-blue-soft",
                    "bg-examedi-blue-strong hover:bg-examedi-blue-soft",
                    "drop-shadow-examedi-blue-strong",
                  )}
                >
                  Derivar
                </button>
              </div>
            }
          />
        );
      })}
    </CollapsibleHandler>
  );
}

export default CollapsibleSpecialities;
