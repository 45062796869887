import React from "react";

import clsx from "clsx";
import { Link } from "gatsby";

import Button from "./Button";

type ButtonLinkProps = {
  text: string;
  to: string;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: JSX.Element | null;
  className?: string;
  textClassName?: string;
  onClick?: () => void;
};

function ButtonLink({ text, to, isLoading, disabled, icon, className, textClassName }: ButtonLinkProps) {
  return (
    <>
      {isLoading && <Button isLoading text={text} icon={icon} className={className} textClassName={textClassName} />}
      {!isLoading && disabled && (
        <Button disabled text={text} icon={icon} className={className} textClassName={textClassName} />
      )}
      {!isLoading && !disabled && (
        <Link
          to={to}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx("button group flex items-center justify-center", !!icon && "gap-x-2", className)}
        >
          {!!icon && (
            <div className="w-fit">
              {React.cloneElement(icon, {
                className: clsx(
                  (icon.props as { className: string }).className,
                  "h-5 w-5 group-disabled:!stroke-white",
                ),
              })}
            </div>
          )}
          {!!text && <div className={clsx("w-fit group-disabled:!text-white", textClassName)}>{text}</div>}
        </Link>
      )}
    </>
  );
}

export default React.memo(ButtonLink);
