/**
 * For more details
 * https://www.notion.so/examedi/Button-0e967637b4ff449297e8b77d677ee895
 */

import React from "react";

import clsx from "clsx";

import LoadingSpinnerIcon from "~/src/icons/LoadingSpinnerIcon";

export type ButtonProps = {
  text?: string;
  icon?: JSX.Element | null;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: (arg?: React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => void;
  className?: string;
  textClassName?: string;
};

function Button({ text, icon, isLoading, disabled, onClick, className, textClassName }: ButtonProps) {
  return (
    <button
      type={onClick ? "button" : "submit"}
      disabled={disabled}
      className={clsx("button group flex items-center justify-center gap-x-2", isLoading && "loading", className)}
    >
      {isLoading && <LoadingSpinnerIcon />}
      {!isLoading && (
        <>
          {!!icon && (
            <div className="w-fit">
              {React.cloneElement(icon, {
                className: clsx(
                  (icon.props as { className: string }).className,
                  "h-5 w-5 group-disabled:!stroke-white",
                ),
              })}
            </div>
          )}
          {!!text && <div className={clsx("w-fit group-disabled:!text-white", textClassName)}>{text}</div>}
        </>
      )}
    </button>
  );
}

export default React.memo(Button);
